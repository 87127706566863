@tailwind base;
@tailwind components;
@tailwind utilities;

/* 여기에 추가적인 전역 스타일을 정의할 수 있습니다 */
body {
  font-family: 'Arial', sans-serif;
}

/* 예: 모든 링크에 대한 스타일 */
a {
  @apply text-blue-600 hover:text-blue-800;
}

/* 예: 모든 헤딩에 대한 스타일 */
h1, h2, h3, h4, h5, h6 {
  @apply font-bold mb-2;
}

/* 기타 전역 스타일... */

@font-face {
  font-family: 'Pretendard';
  src: url('../fonts/Pretendard-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../fonts/Pretendard-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../fonts/Pretendard-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../fonts/Pretendard-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Pretendard';
  src: url('../fonts/Pretendard-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: 'Pretendard', sans-serif;
}