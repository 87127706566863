.list-services {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 1196px;
  margin: 0 auto;
}

.line {
  width: 100%;
  height: 8px;
  background: black;
}

.row-service {
  display: flex;
  flex-direction: row;
  gap: 80px;
  align-items: center;
}

.img1 {
  width: 576px;
  height: 728px;
  background-size: cover;
  background-position: center;
  position: relative;
  transition: transform 0.5s ease-in-out;
}

.img2 {
  width: 576px;
  height: 408px;
  background-size: cover;
  background-position: center;
  position: relative;
  transition: transform 0.5s ease-in-out;
}

.img3 {
  width: 576px;
  height: 256px;
  background-size: cover;
  background-position: center;
  position: relative;
  transition: transform 0.5s ease-in-out;
}

.img1:hover {
  transform: scale(1.1);
}

.img2:hover {
  transform: scale(1.1);
}

.img3:hover {
  transform: scale(1.1);
}

.image-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.union-icon {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  z-index: 10;
}

.title {
  width: 100%;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  position: relative;
}

.main-content-service {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 540px;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.text {
  display: flex;
  align-items: center;
  gap: 16px;
}

.service-number,
.service-title {
  font-size: 36px;
  color: black;
  font-weight: bold;
}

.service-title.title-main {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
}

.content p {
  color: black;
  font-size: 20px;
  font-weight: 100;
}

.detail-button {
  width: 154px;
  height: 48px;
  border: 1.5px solid #2e3190;
  background: #e1e2ff;
  color: #2e3190;
  border-radius: 100px;
  font-weight: bold;
  cursor: pointer;
}
