/* 슬라이딩 텍스트의 컨테이너 */
.text-sliding-wrapper {
  position: absolute;
  width: 100%;
  background: black;
  overflow: hidden;
}

/* 텍스트가 슬라이드하는 애니메이션 */
.text-sliding-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: fit-content;
  height: 72px;
  /* 15s -> 25s */
  animation: slideText 20s linear infinite;
}

/* 슬라이딩되는 텍스트 요소 */
.text-item {
  font-family: 'Pretendard', sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
}

/* 텍스트 사이에 있는 흰색 구분선 */
.text-divider {
  width: 6px;
  height: 24px;
  background: #ffffff;
}

/* 텍스트 슬라이드 애니메이션 정의 */
@keyframes slideText {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
